<template>
  <nav class="navbar min-w-1400">
    <div class="secondBox max-w-1400">
      <div class="logo">
        <!-- <img
          :src="require('@/page/ceibamóvil/components/img/logo.png')"
          :alt="logo"
          class="logoImg"
        /> -->
        <span class="title">Apoyo Frame, S.A. de C.V., SOFOM, E.N.R.</span>
      </div>
      <ul class="nav-links">
        <li :class="{ 'active-link': isActive('/') }" class="Li1">
          <router-link to="/" class="nav-link1">
            <span class="routeText1">Pagina de inicio</span>
          </router-link>
        </li>
        <li :class="{ 'active-link': $route.path === '/about' }" class="Li2">
          <router-link to="/about" class="nav-link2">
            <span class="routeText2">productos y servicios</span>
          </router-link>
        </li>
        <li
          :class="{ 'active-link': $route.path === '/questions' }"
          class="Li3">
          <router-link to="/questions" class="nav-link3">
            <span class="routeText3">Problema comun</span>
          </router-link>
        </li>
        <!-- <li :class="{ 'active-link': $route.path === '/protocol' }" class="Li4">
          <a href="https://acmiw.framecorporativo.com.mx/help/privacy_agreement" class="nav-link3" target="_blank">
            <span class="routeText4">Acuerdo de privacidad</span>
          </a>
        </li> -->
        <li class="dropdown Li4">
          <!-- 添加下拉图标 -->

          <span class="dropdown-toggle routeText4"
            >Acuerdo de privacidad</span
          >
          <ul class="dropdown-menu">
            <li>
              <a href="https://acmiw.framecorporativo.com.mx/help/privacy_agreement"
                target="_blank">App store</a>
            </li>
            <li>
              <a href="https://acmaw.framecorporativo.com.mx/help/privacy_agreement"
                target="_blank">GooglePlay</a>
            </li>
          </ul>

        </li>
        
         <!-- <li
          :class="{
            'active-link': ['/questions', '/protocol'].includes($route.path),
          }"
          class="Li4"
        >
          <router-link to="/questions" class="nav-link4">
            <span class="routeText4">Vấn đề Thường Gặp</span>
          </router-link>
        </li> -->
        <!-- <li :class="{ 'active-link': $route.path === '/protocol' }" class="Li5">
          <router-link to="/protocol" class="nav-link5">
            <span class="routeText5">Acuerdo de privacidad</span>
          </router-link>
        </li> -->
      </ul>
    </div>
    <div style="height: 130px"></div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      link1Color: "white",
      isShow: false,
    };
  },
  // created() {
  //   this.getUrlParams();
  // },
  methods: {
    isActive(route) {
      return this.$route.path === route;
    },

    showOptions() {
      if (this.isShow) {
        this.isShow = false;
      } else {
        this.isShow = true;
      }
    },
    closeOptions() {
      this.isShow = false;
    },
    openPDF() {
      window.open('/Business Registration Certificate.pdf')
    },
    // getUrlParams() {
    //   const url = window.location.href;
    //   let obj = {};
    //   try {
    //     let urlStr = url.split("?")[1];
    //     let paramsArr = urlStr.split("&");
    //     for (let i = 0, len = paramsArr.length; i < len; i++) {
    //       let arr = paramsArr[i].split("=");
    //       obj[arr[0]] = arr[1];
    //     }
    //   } catch (error) {
    //     console.error("An error occurred while parsing URL parameters:", error);
    //   }
    //   const pdfDom = document.getElementById("pdf-iframe");
    //   const docid = document.getElementById("docid");
    //   if (obj) {
    //     docid.textContent = obj.docid || "";
    //     pdfDom.setAttribute(
    //       "src",
    //       `./assets/${(obj.token || "").toLowerCase()}.pdf`
    //     );
    //   }
    // },
  },
};
</script>
<style scoped>
.navbar {
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  position: sticky;  /* 将导航栏固定在页面上 */
  top: 0;  /* 位于页面顶部 */
  z-index: 1000;  /* 确保导航栏在其他元素上方 */
  height: 70px;
}
.secondBox {
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: 70px;
  padding: 0 30px;
  background-color: #FFFFFF;
  border-bottom: 2px solid #F7F7FA;
}

.logo {
  font-weight: bold;
  color: black;
  display: flex;
  align-items: center;
}

.logoImg {
  width: 60px;
  height: 60px;
}

.title {
  width: 520px;
  height: 24px;
  font-size: 18px;
  font-family: SF Pro-Black Italic;
  font-weight: 600;
  color: #292B33;
  line-height: 23px;
  padding-left: 17px;
  /* text-transform: uppercase; */
}

.routeText1,
.routeText2,
.routeText3,
.routeText4,
.routeText5 {
  font-size: 16px;
  font-family: SF Pro-Semibold;
  font-weight: 550;
  color: #292B33;
  line-height: 29px;
  /* text-transform: uppercase; */
}

.nav-link1,
.nav-link2,
.nav-link3,
.nav-link4,
.nav-link5 {
  text-decoration: none;
  margin: 0 auto;
  text-align: center;
}

.nav-links {
  list-style: none;
  display: flex;
  align-items: center;
  /* 垂直居中对齐 */
  justify-content: center;
  /* 水平居中对齐 */
}

.nav-links li {
  display: flex;
  padding: 8px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 70px;
  padding-left: 4px;
  padding-right: 4px;
  line-height: 50px;
}
.Li1 {
  width: 180px;
}
.Li2 {
  width: 180px;
}
.Li3 {
  width: 180px;
}
.Li4 {
  width: 230px;
}
/* .Li5 {
  width: 250px;
} */
/* 这个如果给margin-right底部可能会出现边距的问题 */
/* .Li4 {
  width: 250px;
  
} */


.active-link {
  /* background: linear-gradient(45deg, #F59120, #F95C40); */
  background-color: #F0F1FF;
  /* border-color: #dae633; */
  /* border-radius: 10px; */
}

.active-link .routeText1,
.active-link .routeText2,
.active-link .routeText3,
.active-link .routeText4,
.active-link .routeText5 {
  font-family: Inter-Extra Bold;

  color: #5B26EB;
}
.routeText4{
  line-height: 50px;
  margin-left: 20px;
}
.dropdown {
  position: relative;
  
}

.dropdown-toggle {
  cursor: pointer;
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  /* left: 0; */
  left: 20px;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 5px 0;
  min-width: 100px;
  width: 160px;
}

.dropdown-menu li {
  padding: 5px 30px;
}

.dropdown-menu li:hover {
  background-color: #F7F7FA;
}

.dropdown:hover .dropdown-menu {
  display: block;
}
.dropdown-menu li a {
  font-size: 13px;
  text-decoration: none;
  font-family: SF Pro-Semibold;
  font-weight: 550;
  color: #626673;
}
.dropdown-menu li a:hover{
  color: #5B26EB;
}
.dropdown-icon {
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #000; /* 设置倒三角形的颜色 */
  border-bottom: none; /* 去除下边框 */
  margin-left: 5px;
  vertical-align: middle;
}
</style>