<template>
  <div id="app">
    <!-- <MyHeaderVue1 /> -->
    <!-- <MyHeaderVue v-if="!['/about', '/questions', '/protocol'].includes($route.path)" /> -->
    <MyHeaderVue />

    <router-view></router-view>
    <MyFooterVue />
  </div>
</template>

<script>
// import MyHeaderVue from './page/chatchaimobile/MyHeader.vue'
// import MyFooterVue from './page/chatchaimobile/MyFooter.vue'

//jaidee官网
// import MyHeaderVue from './page/jaidee/MyHeader.vue'
// import MyFooterVue from './page/jaidee/MyFooter.vue'

//jaideeconnect
// import MyHeaderVue from './page/jaideeconnect/MyHeader.vue'
// import MyFooterVue from './page/jaideeconnect/MyFooter.vue'

//越南kasikvay
// import MyHeaderVue from './page/kasikvay/MyHeader.vue'
// import MyFooterVue from './page/kasikvay/MyFooter.vue'

//越南tamogiefectivo
// import MyHeaderVue from './page/tamogiefectivo/MyHeader.vue'
// import MyFooterVue from './page/tamogiefectivo/MyFooter.vue'

//墨西哥consedeefectivo
// import MyHeaderVue from './page/consedeefectivo/MyHeader.vue'
// import MyFooterVue from './page/consedeefectivo/MyFooter.vue'

//越南nathcopay
// import MyHeaderVue from './page/components/MyHeader5.vue'
// import MyFooterVue from './page/components/MyFooter3.vue'

//越南mrVaymobile
// import MyHeaderVue from './page/mrVaymobile/MyHeader.vue'
// import MyFooterVue from './page/mrVaymobile/MyFooter.vue'

//越南Hcthlog Plus
// import MyHeaderVue from './page/hcthlogplus/MyHeader.vue'
// import MyFooterVue from './page/hcthlogplus/MyFooter.vue'

//墨西哥hipotecariabilletera
// import MyHeaderVue from './page/hipotecariabilletera/MyHeader.vue'
// import MyFooterVue from './page/hipotecariabilletera/MyFooter.vue'

//越南sunmobile
// import MyHeaderVue from './page/sunmobile/MyHeader.vue'
// import MyFooterVue from './page/sunmobile/MyFooter.vue'

//越南cathaydonge
// import MyHeaderVue from './page/cathaydong/MyHeader.vue'
// import MyFooterVue from './page/cathaydong/MyFooter.vue'

//越南newvay
// import MyHeaderVue from './page/newvay/MyHeader.vue'
// import MyFooterVue from './page/newvay/MyFooter.vue'

//墨西哥eldefiardinero
// import MyHeaderVue from './page/eldefiardinero/MyHeader.vue'
// import MyFooterVue from './page/eldefiardinero/MyFooter.vue'

//越南thanhchungplus
// import MyHeaderVue from './page/thanhchungplus/MyHeader.vue'
// import MyFooterVue from './page/thanhchungplus/MyFooter.vue'

//墨西哥dealcorpefectivo
// import MyHeaderVue from './page/dealcorpefectivo/MyHeader.vue'
// import MyFooterVue from './page/dealcorpefectivo/MyFooter.vue'

//墨西哥kampalacash
// import MyHeaderVue from './page/kampalacash/MyHeader.vue'
// import MyFooterVue from './page/kampalacash/MyFooter.vue'

//泰国pheera
// import MyHeaderVue from './page/pheera/MyHeader.vue'
// import MyFooterVue from './page/pheera/MyFooter.vue'

//越南piwbplus
// import MyHeaderVue from './page/piwebplus/MyHeader.vue'
// import MyFooterVue from './page/piwebplus/MyFooter.vue'

//越南piwebplus
// import MyHeaderVue from './page/piwebplus2/MyHeader.vue'
// import MyFooterVue from './page/piwebplus2/MyFooter.vue'

//墨西哥directodoefectivo
// import MyHeaderVue from './page/directodoefectivo/MyHeader.vue'
// import MyFooterVue from './page/directodoefectivo/MyFooter.vue'

//泰国Jaideeonline
// import MyHeaderVue from './page/Jaideeonline/MyHeader.vue'
// import MyFooterVue from './page/Jaideeonline/MyFooter.vue'

//墨西哥braskemefectivo
// import MyHeaderVue from './page/braskemefectivo/MyHeader.vue'
// import MyFooterVue from './page/braskemefectivo/MyFooter.vue'

//墨西哥dealcorpefectivo2
// import MyHeaderVue from './page/dealcorpefectivo2/MyHeader.vue'
// import MyFooterVue from './page/dealcorpefectivo2/MyFooter.vue'

//墨西哥ceibamóvil
// import MyHeaderVue from './page/ceibamóvil/MyHeader.vue'
// import MyFooterVue from './page/ceibamóvil/MyFooter.vue'

//墨西哥estimulocrédito
// import MyHeaderVue from './page/estimulocrédito/MyHeader.vue'
// import MyFooterVue from './page/estimulocrédito/MyFooter.vue'

//墨西哥cincodospréstamos
// import MyHeaderVue from './page/cincodospréstamos/MyHeader.vue'
// import MyFooterVue from './page/cincodospréstamos/MyFooter.vue'

//墨西哥concreecefectivo
// import MyHeaderVue from './page/concreecefectivo/MyHeader.vue'
// import MyFooterVue from './page/concreecefectivo/MyFooter.vue'

//墨西哥daraptipago和wiicredi
// import MyHeaderVue from './page/daraptipago/MyHeader.vue'
// import MyFooterVue from './page/daraptipago/MyFooter.vue'

//泰国microfinmobile
// import MyHeaderVue from './page/microfinmobile/MyHeader.vue'
// import MyFooterVue from './page/microfinmobile/MyFooter.vue'

//墨西哥ammóvil
// import MyHeaderVue from './page/ammóvil/MyHeader.vue'
// import MyFooterVue from './page/ammóvil/MyFooter.vue'

//墨西哥ngernsaen
// import MyHeaderVue from './page/ngernsaen/MyHeader.vue'
// import MyFooterVue from './page/ngernsaen/MyFooter.vue'

//泰国domcap
// import MyHeaderVue from './page/domcap/MyHeader.vue'
// import MyFooterVue from './page/domcap/MyFooter.vue'

//墨西哥cherreypréstamos2
// import MyHeaderVue from './page/cherreypréstamos2/MyHeader.vue'
// import MyFooterVue from './page/cherreypréstamos2/MyFooter.vue'

//墨西哥facicasapresta
// import MyHeaderVue from './page/facicasapresta/MyHeader.vue'
// import MyFooterVue from './page/facicasapresta/MyFooter.vue'

//墨西哥eliteloan
// import MyHeaderVue from './page/eliteloan/MyHeader.vue'
// import MyFooterVue from './page/eliteloan/MyFooter.vue'

//越南drkinhtiềnbạc
// import MyHeaderVue from './page/drkinhtiềnbạc/MyHeader.vue'
// import MyFooterVue from './page/drkinhtiềnbạc/MyFooter.vue'

//越南vajefinance
// import MyHeaderVue from './page/vajefinance/MyHeader.vue'
// import MyFooterVue from './page/vajefinance/MyFooter.vue'

//越南petrolivay
// import MyHeaderVue from './page/petrolivay/MyHeader.vue'
// import MyFooterVue from './page/petrolivay/MyFooter.vue'

//越南lestardong
// import MyHeaderVue from './page/lestardong/MyHeader.vue'
// import MyFooterVue from './page/lestardong/MyFooter.vue'

//越南sunvay
// import MyHeaderVue from './page/sunvay/MyHeader.vue'
// import MyFooterVue from './page/sunvay/MyFooter.vue'

//墨西哥paratidinero
// import MyHeaderVue from './page/paratidinero/MyHeader.vue'
// import MyFooterVue from './page/paratidinero/MyFooter.vue'

//泰国microfin2
// import MyHeaderVue from "./page/microfin2/MyHeader.vue";
// import MyHeaderVue1 from "./page/microfin2/MyHeader1.vue";
// import MyFooterVue from "./page/microfin2/MyFooter.vue";

//泰国domcap2
// import MyHeaderVue from './page/domcap2/MyHeader.vue'
// import MyFooterVue from './page/domcap2/MyFooter.vue'

//泰国ngernsaen2
// import MyHeaderVue from './page/ngernsaen2/MyHeader.vue'
// import MyFooterVue from './page/ngernsaen2/MyFooter.vue'

//越南firefigvay
// import MyHeaderVue from './page/firefigvay/MyHeader.vue'
// import MyFooterVue from './page/firefigvay/MyFooter.vue'

//越南sonthuyvay
// import MyHeaderVue from './page/sonthuyvay/MyHeader.vue'
// import MyFooterVue from './page/sonthuyvay/MyFooter.vue'

//越南truongtaionline
// import MyHeaderVue from './page/truongtaionline/MyHeader.vue'
// import MyFooterVue from './page/truongtaionline/MyFooter.vue'

//越南dhloan
// import MyHeaderVue from './page/dhloan/MyHeader.vue'
// import MyFooterVue from './page/dhloan/MyFooter.vue'

//墨西哥janodinero||fina
// import MyHeaderVue from './page/janodinero/MyHeader.vue'
// import MyFooterVue from './page/janodinero/MyFooter.vue'

//墨西哥kishonefectivo
// import MyHeaderVue from './page/kishonefectivo/MyHeader.vue'
// import MyFooterVue from './page/kishonefectivo/MyFooter.vue'

//越南longgsloan
// import MyHeaderVue from './page/longgsloan/MyHeader.vue'
// import MyFooterVue from './page/longgsloan/MyFooter.vue'

//越南hanh
// import MyHeaderVue from './page/hanh/MyHeader.vue'
// import MyFooterVue from './page/hanh/MyFooter.vue'

//泰国subkamolplus
// import MyHeaderVue from './page/subkamolplus/MyHeader.vue'
// import MyFooterVue from './page/subkamolplus/MyFooter.vue'

//泰国ktcnano
// import MyHeaderVue from './page/ktcnano/MyHeader.vue'
// import MyFooterVue from './page/ktcnano/MyFooter.vue'

//泰国nano
// import MyHeaderVue from './page/nano/MyHeader.vue'
// import MyFooterVue from './page/nano/MyFooter.vue'

// 墨西哥lemonade
// import MyHeaderVue from "./page/lemonade/MyHeader.vue";
// import MyFooterVue from "./page/lemonade/MyFooter.vue";

//越南cbmalldong
// import MyHeaderVue from './page/cbmalldong/MyHeader.vue'
// import MyFooterVue from './page/cbmalldong/MyFooter.vue'

//越南onsenmobile
// import MyHeaderVue from './page/onsenmobile/MyHeader.vue'
// import MyFooterVue from './page/onsenmobile/MyFooter.vue'

//墨西哥lordanoupréstamos
// import MyHeaderVue from './page/lordanoupréstamos/MyHeader.vue'
// import MyFooterVue from './page/lordanoupréstamos/MyFooter.vue'

//越南lordanoupréstamos2
// import MyHeaderVue from './page/lordanoupréstamos2/MyHeader.vue'
// import MyFooterVue from './page/lordanoupréstamos2/MyFooter.vue'

//泰国creditmonitor
// import MyHeaderVue from './page/creditmonitor/MyHeader.vue'
// import MyFooterVue from './page/creditmonitor/MyFooter.vue'

//墨西哥mejorandopresta
// import MyHeaderVue from './page/mejorandopresta/MyHeader.vue'
// import MyFooterVue from './page/mejorandopresta/MyFooter.vue'

// //越南koocheemoney
// import MyHeaderVue from './page/koocheemoney/MyHeader.vue'
// import MyFooterVue from './page/koocheemoney/MyFooter.vue'

//墨西哥nitriodinero
// import MyHeaderVue from './page/nitriodinero/MyHeader.vue'
// import MyFooterVue from './page/nitriodinero/MyFooter.vue'

//越南nitriodinero2
// import MyHeaderVue from './page/nitriodinero2/MyHeader.vue'
// import MyFooterVue from './page/nitriodinero2/MyFooter.vue'

//越南zscredit
// import MyHeaderVue from './page/zscredit/MyHeader.vue'
// import MyFooterVue from './page/zscredit/MyFooter.vue'

// //墨西哥joylendefectivo
// import MyHeaderVue from './page/joylendefectivo/MyHeader.vue'
// import MyFooterVue from './page/joylendefectivo/MyFooter.vue'

//墨西哥activosmóvil
// import MyHeaderVue from "./page/activosmóvil/MyHeader.vue";
// import MyHeaderVue1 from "./page/activosmóvil/MyHeader1.vue";
// import MyFooterVue from "./page/activosmóvil/MyFooter.vue";

//越南giafinance
// import MyHeaderVue from './page/giafinance/MyHeader.vue'
// import MyFooterVue from './page/giafinance/MyFooter.vue'

//越南debutvay
// import MyHeaderVue from './page/debutvay/MyHeader.vue'
// import MyFooterVue from './page/debutvay/MyFooter.vue'

//墨西哥easycommu
// import MyHeaderVue from './page/easycommu/MyHeader.vue'
// import MyFooterVue from './page/easycommu/MyFooter.vue'

//泰国sinthorn
// import MyHeaderVue from './page/sinthorn/MyHeader.vue'
// import MyFooterVue from './page/sinthorn/MyFooter.vue'

//墨西哥kampalacash2
// import MyHeaderVue from './page/kampalacash2/MyHeader.vue'
// import MyFooterVue from './page/kampalacash2/MyFooter.vue'

//泰国chookiat
// import MyHeaderVue from './page/chookiat/MyHeader.vue'
// import MyFooterVue from './page/chookiat/MyFooter.vue'

//越南hathanh
// import MyHeaderVue from './page/hathanh/MyHeader.vue'
// import MyFooterVue from './page/hathanh/MyFooter.vue'

//越南hoabinhdong
// import MyHeaderVue from './page/hoabinhdong/MyHeader.vue'
// import MyFooterVue from './page/hoabinhdong/MyFooter.vue'

//越南pakvay
// import MyHeaderVue from './page/pakvay/MyHeader.vue'
// import MyFooterVue from './page/pakvay/MyFooter.vue'

//墨西哥paraeldinero
// import MyHeaderVue from './page/paraeldinero/MyHeader.vue'
// import MyFooterVue from './page/paraeldinero/MyFooter.vue'

//越南subkamollending
// import MyHeaderVue from './page/subkamollending/MyHeader.vue'
// import MyFooterVue from './page/subkamollending/MyFooter.vue'

//墨西哥apoyopresta
import MyHeaderVue from './page/apoyopresta/MyHeader.vue'
import MyFooterVue from './page/apoyopresta/MyFooter.vue'

//越南namadong
// import MyHeaderVue from './page/namadong/MyHeader.vue'
// import MyFooterVue from './page/namadong/MyFooter.vue'

//越南cdrvaytiền
// import MyHeaderVue from './page/cdrvaytiền/MyHeader.vue'
// import MyFooterVue from './page/cdrvaytiền/MyFooter.vue'

//墨西哥wisementpresta
// import MyHeaderVue from './page/wisementpresta/MyHeader.vue'
// import MyFooterVue from './page/wisementpresta/MyFooter.vue'

//墨西哥urieosmóvil
// import MyHeaderVue from './page/urieosmóvil/MyHeader.vue'
// import MyFooterVue from './page/urieosmóvil/MyFooter.vue'

//越南thtonline
// import MyHeaderVue from './page/thtonline/MyHeader.vue'
// import MyFooterVue from './page/thtonline/MyFooter.vue'

//墨西哥núcleobilletera
// import MyHeaderVue2 from "./page/núcleobilletera/MyHeader2.vue"
// import MyHeaderVue from "./page/núcleobilletera/MyHeader.vue";
// import MyFooterVue from "./page/núcleobilletera/MyFooter.vue";

//墨西哥oportunifyloan
// import MyHeaderVue from './page/oportunifyloan/MyHeader.vue'
// import MyFooterVue from './page/oportunifyloan/MyFooter.vue'

//越南namdomobile
// import MyHeaderVue from './page/namdomobile/MyHeader.vue'
// import MyFooterVue from './page/namdomobile/MyFooter.vue'

//越南manhtienvay
// import MyHeaderVue from './page/manhtienvay/MyHeader.vue'
// import MyFooterVue from './page/manhtienvay/MyFooter.vue'

//越南artemisdong
// import MyHeaderVue from './page/artemisdong/MyHeader.vue'
// import MyFooterVue from './page/artemisdong/MyFooter.vue'

//越南huyenvay
// import MyHeaderVue from './page/huyenvay/MyHeader.vue'
// import MyFooterVue from './page/huyenvay/MyFooter.vue'

//墨西哥baranpago
// import MyHeaderVue from './page/baranpago/MyHeader.vue'
// import MyFooterVue from './page/baranpago/MyFooter.vue'

//墨西哥tongshiimportacion
// import MyHeaderVue from './page/tongshiimportacion/MyHeader.vue'
// import MyFooterVue from './page/tongshiimportacion/MyFooter.vue'

//墨西哥coparfindinero
// import MyHeaderVue from './page/coparfindinero/MyHeader.vue'
// import MyFooterVue from './page/coparfindinero/MyFooter.vue'

//越南pacwallet
// import MyHeaderVue from './page/pacwallet/MyHeader.vue'
// import MyFooterVue from './page/pacwallet/MyFooter.vue'

//越南quocvay
// import MyHeaderVue from './page/quocvay/MyHeader.vue'
// import MyFooterVue from './page/quocvay/MyFooter.vue'

//越南quangcash
// import MyHeaderVue from './page/quangcash/MyHeader.vue'
// import MyFooterVue from './page/quangcash/MyFooter.vue'

//越南tcamvay
// import MyHeaderVue from './page/tcamvay/MyHeader.vue'
// import MyFooterVue from './page/tcamvay/MyFooter.vue'

//墨西哥confianegocios
// import MyHeaderVue from './page/confianegocios/MyHeader.vue'
// import MyFooterVue from './page/confianegocios/MyFooter.vue'

//墨西哥animoparacrecer
// import MyHeaderVue from './page/animoparacrecer/MyHeader.vue'
// import MyFooterVue from './page/animoparacrecer/MyFooter.vue'

//墨西哥agorarecursos
// import MyHeaderVue from './page/agorarecursos/MyHeader.vue'
// import MyFooterVue from './page/agorarecursos/MyFooter.vue'

//泰国kwamdeebusiness
// import MyHeaderVue from './page/kwamdeebusiness/MyHeader.vue'
// import MyFooterVue from './page/kwamdeebusiness/MyFooter.vue'

//泰国jengtimber
// import MyHeaderVue from './page/jengtimber/MyHeader.vue'
// import MyFooterVue from './page/jengtimber/MyFooter.vue'

//越南anhitinformation
// import MyHeaderVue from './page/anhitinformation/MyHeader.vue'
// import MyFooterVue from './page/anhitinformation/MyFooter.vue'

//越南hungdaianapp
// import MyHeaderVue from './page/hungdaianapp/MyHeader.vue'
// import MyFooterVue from './page/hungdaianapp/MyFooter.vue'

//越南donghaisoftware
// import MyHeaderVue from './page/donghaisoftware/MyHeader.vue'
// import MyFooterVue from './page/donghaisoftware/MyFooter.vue'

//越南tandatphatmoney
// import MyHeaderVue from './page/tandatphatmoney/MyHeader.vue'
// import MyFooterVue from './page/tandatphatmoney/MyFooter.vue'

//越南smartiesconsulting
// import MyHeaderVue from './page/smartiesconsulting/MyHeader.vue'
// import MyFooterVue from './page/smartiesconsulting/MyFooter.vue'

//越南lvsoftcompanylimited
// import MyHeaderVue from './page/lvsoftcompanylimited/MyHeader.vue'
// import MyFooterVue from './page/lvsoftcompanylimited/MyFooter.vue'

//墨西哥donapoyo
// import MyHeaderVue from './page/donapoyo/MyHeader.vue'
// import MyFooterVue from './page/donapoyo/MyFooter.vue'

//泰国vcwealthloan
// import MyHeaderVue from './page/vcwealthloan/MyHeader.vue'
// import MyFooterVue from './page/vcwealthloan/MyFooter.vue'

//墨西哥inmediprest
// import MyHeaderVue from './page/inmediprest/MyHeader.vue'
// import MyFooterVue from './page/inmediprest/MyFooter.vue'

//墨西哥foniclub
// import MyHeaderVue from './page/foniclub/MyHeader.vue'
// import MyFooterVue from './page/foniclub/MyFooter.vue'

//墨西哥alorsi
// import MyHeaderVue from './page/alorsi/MyHeader.vue'
// import MyFooterVue from './page/alorsi/MyFooter.vue'

//越南bonodilecold
// import MyHeaderVue from './page/bonodilecold/MyHeader.vue'
// import MyFooterVue from './page/bonodilecold/MyFooter.vue'

//泰国mitrsinduan
// import MyHeaderVue from './page/mitrsinduan/MyHeader.vue'
// import MyFooterVue from './page/mitrsinduan/MyFooter.vue'

//墨西哥generandorlq
// import MyHeaderVue from './page/generandorlq/MyHeader.vue'
// import MyFooterVue from './page/generandorlq/MyFooter.vue'

//墨西哥zchogorcredito
// import MyHeaderVue from './page/zchogorcredito/MyHeader.vue'
// import MyFooterVue from './page/zchogorcredito/MyFooter.vue'

//墨西哥conficreosofom
// import MyHeaderVue from './page/conficreosofom/MyHeader.vue'
// import MyFooterVue from './page/conficreosofom/MyFooter.vue'

//越南dreamcashddthanh
// import MyHeaderVue from './page/dreamcashddthanh/MyHeader.vue'
// import MyFooterVue from './page/dreamcashddthanh/MyFooter.vue'

//越南chickenfiretown
// import MyHeaderVue from './page/chickenfiretown/MyHeader.vue'
// import MyFooterVue from './page/chickenfiretown/MyFooter.vue'

//越南mangomoneyapp
// import MyHeaderVue from './page/mangomoneyapp/MyHeader.vue'
// import MyFooterVue from './page/mangomoneyapp/MyFooter.vue'

//越南maybaengcompanylimitedapp
// import MyHeaderVue from './page/maybaengcompanylimitedapp/MyHeader.vue'
// import MyFooterVue from './page/maybaengcompanylimitedapp/MyFooter.vue'

//墨西哥kartermax
// import MyHeaderVue from './page/kartermax/MyHeader.vue'
// import MyFooterVue from './page/kartermax/MyFooter.vue'

//越南hauthuycompanylid
// import MyHeaderVue from './page/hauthuycompanylid/MyHeader.vue'
// import MyFooterVue from './page/hauthuycompanylid/MyFooter.vue'

//越南hanvanthuycoldapp
// import MyHeaderVue from './page/hanvanthuycoldapp/MyHeader.vue'
// import MyFooterVue from './page/hanvanthuycoldapp/MyFooter.vue'

//墨西哥fidecreo
// import MyHeaderVue from './page/fidecreo/MyHeader.vue'
// import MyFooterVue from './page/fidecreo/MyFooter.vue'

//墨西哥ttbluescapital
// import MyHeaderVue from './page/ttbluescapital/MyHeader.vue'
// import MyFooterVue from './page/ttbluescapital/MyFooter.vue'

//泰国aira-aiful
// import MyHeaderVue from './page/aira-aiful/MyHeader.vue'
// import MyFooterVue from './page/aira-aiful/MyFooter.vue'

//越南thaidinhphatapp
// import MyHeaderVue from './page/thaidinhphatapp/MyHeader.vue'
// import MyFooterVue from './page/thaidinhphatapp/MyFooter.vue'

//越南speedyloanapp
// import MyHeaderVue from './page/speedyloanapp/MyHeader.vue'
// import MyFooterVue from './page/speedyloanapp/MyFooter.vue'

//越南happycreditapp
// import MyHeaderVue from './page/happycreditapp/MyHeader.vue'
// import MyFooterVue from './page/happycreditapp/MyFooter.vue'

//越南financialcompanionlvay
// import MyHeaderVue from './page/financialcompanionlvay/MyHeader.vue'
// import MyFooterVue from './page/financialcompanionlvay/MyFooter.vue'

//墨西哥agroatoto
// import MyHeaderVue from './page/agroatoto/MyHeader.vue'
// import MyFooterVue from './page/agroatoto/MyFooter.vue'

//越南loanwaveapp
// import MyHeaderVue from './page/loanwaveapp/MyHeader.vue'
// import MyFooterVue from './page/loanwaveapp/MyFooter.vue'



export default {
  name: "App",
  components: {
    // MyHeaderVue1,
    MyHeaderVue,
    MyFooterVue,
  },
};
</script>

<style>
#app {
  color: #2c3e50;
}
</style>
